/* eslint-disable no-console */
import React, { useState } from 'react';
import classnames from 'classnames';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import * as QuizButton from '../QuizButton/QuizButton';
import { getContent, getAudio } from '../../../utilities/getContent/getContent';
import { color, emoticon, emoji } from './config';
const TYPES = {
  FAT: 'fat',
  SKINNY: 'skinny',
};

const QuizButtonGroup = ({
  answers,
  questionType,
  answerSelected,
  children,
  quizType,
  isSelected,
  elementary,
  questionId,
  // responseType,
}) => {
  // console.log(`responseType = ${responseType}`);
  const tempOptions = [];
  answers.map(answer => tempOptions.push(answer.text));

  const [activeAnswer, setActiveAnswer] = useState({
    selectedIndex: null,
    selectedValue: null,
    options: tempOptions,
  });

  const toggleSelected = (index, value) => {
    setActiveAnswer(() => {
      answerSelected(true, value);
      return {
        selectedIndex: index,
        selectedValue: activeAnswer.options[index],
        options: activeAnswer.options,
      };
    });
  };
  const getAnswers = () => {
    const answersValues = answers.map(a => a.sortValue || a.value);
    if (answersValues.length !== new Set(answersValues).size) { // some answers have the same value, making the sort unpredictable
      // if (responseType && responseType.match(/intro_/) && !answers[0].hasOwnProperty("sortValue")) { 
      // console.log(`Skip sorting answers for quesiton = ${questionId}`);
      return answers
    }
    const sortedAnswers = answers.sort((a, b) => {
      if (a.hasOwnProperty("sortValue") && b.hasOwnProperty("sortValue")) {
        return a.sortValue - b.sortValue;
      } else {
        return (a.value > b.value ? 1 : -1)
      }
    });
    return questionType === 'resilience' || questionType === undefined ? sortedAnswers : sortedAnswers.reverse();
  };
  const questionsWithEmoticons = [
    'd151318a-b333-5547-aed0-75b487060a1e',
    'c5f114cf-56e7-5d8e-918a-6dee97b07c53',
    '8485aeba-d8ac-548a-9e56-8282aa959236',
    'a0469b11-bba0-5503-b783-0787f822bb91',
    'dffd88c9-42e1-559f-aa9e-a368d0ffdc2a',
    '3b545b3d-b762-5265-966f-744b9da34e18',
    'f71879e6-4e0c-5953-957c-3fe0af4326cd',
    '486298a2-cd15-5817-a578-b8a15c671d3c',
    'e8b6e104-3523-534e-b2d1-ee907fe77531',
    'e85d1fa3-02e6-5c18-81f6-43117971f1b3',
    '0406e0e0-9b69-544e-b61c-2c01f90c8a6a',
    'cd708736-052d-54ab-a6d3-ce098e87d794',
    'b6a16839-cc4e-5d02-bfd2-31e56485cabb',
    'e24d047d-82fb-5887-9931-fb8fb4eb5bad',
    '03a166bd-2358-5fd5-a748-e559bf3754d9',
    'b2d813b3-8749-5da5-8513-1a7482646283',
    //    "7f0c7893-6d276c57-5008302b-e7da0920",
    //    "0a21a8f0-f53af00e-0672a665-c14ddae1",
    //    "9276a179-0c6ebfab-4bdcf305-d298eb39",
    //    "025d5a57-0303d7fb-8145f1fd-d35114b5",
    //    "5a2a56ec-cd8a90c0-03d359a8-cad3baad"
  ];
  const shouldDisplayEmoticons = elementary && questionId && questionsWithEmoticons.includes(questionId);
  // const shouldDisplayEmoticons = false; // removed as per Rich's email Feb 6th 2024
  const keys = [];
  answers.map((answer, index) => {
    const newIndex = index + 1;
    return keys.push(`${newIndex}`);
  });
  return (
    <div className={classnames('quizButtonGroup', `${quizType}`)}>
      <KeyboardEventHandler
        handleKeys={keys}
        onKeyEvent={(key, e) => {
          const origIndex = parseInt(key, 10) - 1;
          toggleSelected(origIndex, answers[origIndex].value);
          console.log(key, e);
        }}
      />
      {getAnswers().map((answer, index) => {
        const isActive = activeAnswer.selectedIndex === index && isSelected;
        const icon = answer.emoji ? emoji[answer.emoji] : (shouldDisplayEmoticons ? emoticon[answers.length][index] : null)
        return React.cloneElement(children, {
          text: getContent(answer.field),
          key: index,
          active: isActive,
          index,
          icon: icon,
          color: questionType && answers.length ? color[answers.length][index] : null,
          onClick: toggleSelected,
          value: answer.value,
          audio: getAudio(answer.field),
          elementary: elementary,
        });
      })}
    </div>
  );
};

export const Fat = props => (
  <QuizButtonGroup {...props} quizType={TYPES.FAT}>
    <QuizButton.Fat isFatEmoji={props.isFatEmoji} />
  </QuizButtonGroup>
);
export const Skinny = props => (
  <QuizButtonGroup {...props} quizType={TYPES.SKINNY}>
    <QuizButton.Skinny />
  </QuizButtonGroup>
);
