import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import TableGenerator from '../../../../../components/TableGenerator/TableGenerator';
import { dateFormat, adjustDate } from '../../../../../utilities/dates';
import CheckedImage from '../../../../../assets/imgs/checked.png';
import UncheckedImage from '../../../../../assets/imgs/unchecked.png';
import * as actions from '../../../../../store/actions';
import Overlay from '../../../../../components/Overlay/overlay';
import Spinner from '../../../../../components/Spinner/Spinner';

class BulkDeleteModal extends Component {
  state = {
    participants: [],
    searchedParticipants: [],
    search: '',
    isLoading: false
  };
  componentWillMount() {
    const initialParticipants = this.props.participants.sort((p, q) => p.lastName.localeCompare(q.lastName)).filter(p => p.participantStatus !== "Completed")
      .map((participant, index) => {
        participant.checked = false;
        participant.position = index;
        return participant;
      });
    this.setState({ participants: initialParticipants });
  }


  handleStatusChange = value => {
    console.log(value);
    this.setState({ status: value });
  };

  handleSubmit = () => {
    this.setState({ isLoading: true });

    const surveyParticipantIds = this.state.participants
      .filter(participant => participant.checked === true)
      .map(participant => {
        return participant.surveyParticipantId;
      });
    const deleteParticipantsObject = { participantIds: surveyParticipantIds, surveyId: this.props.surveyId };
    this.props.bulkDeleteParticipant(deleteParticipantsObject);
  };

  searchForNames = e => {
    const searchedParticipants = this.state.participants.filter(
      participant =>
        participant.firstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
        participant.lastName.toLowerCase().includes(e.target.value.toLowerCase()) ||
        (participant.firstName.toLowerCase() + ' ' + participant.lastName.toLowerCase()).includes(
          e.target.value.toLowerCase()
        )
    );
    this.setState({ searchedParticipants, search: e.target.value });
  };

  handleClick = index => {
    const getClickedParticipant = () => {
      if (this.state.search !== '') {
        const participants = this.state.participants.map(participant => {
          if (participant.position === this.state.searchedParticipants[index].position) {
            participant.checked = !participant.checked;
          }
          return participant;
        });
        this.setState({ participants });
      } else {
        const participants = this.state.participants.map(participant => {
          if (participant.position === index) {
            participant.checked = !participant.checked;
          }
          return participant;
        });
        this.setState({ participants });
      }
    };
    getClickedParticipant();
  };

  allParticipantsChecked = () => {
    return this.state.participants.every(p => p.checked);
  }

  checkEveryone = () => {
    const isAllChecked = this.allParticipantsChecked();
    const _participants = this.state.participants.map(p => {
      p.checked = !isAllChecked;
      return p;
    })
    this.setState({ participant: _participants });

  }

  generateTableData = () => {
    const result = this.state.participants.map(participant => ({
      name: `${participant.lastName}, ${participant.firstName}`,
      DOB: adjustDate(participant.birthDate).format(dateFormat),
      selected: (
        <img
          className="checkbox"
          style={{ cursor: 'pointer' }}
          alt="Checkbox"
          src={participant.checked ? CheckedImage : UncheckedImage}
        />
      )
    }));
    if (this.state.search !== '')
      return this.state.searchedParticipants.map(participant => ({
        name: `${participant.lastName}, ${participant.firstName}`,
        DOB: adjustDate(participant.birthDate).format(dateFormat),
        selected: (
          <img
            className="checkbox"
            alt="Checkbox"
            src={participant.checked ? CheckedImage : UncheckedImage}
          />
        )
      }));
    return result;
  };



  render() {

    return (
      <Overlay
        className="survey-status-modal-wrapper"
        isOpen={this.props.show}
        onToggle={this.props.closeModal}
        title={"Delete Participants"}
      >
        <div className='ps-4 pe-4'>
          <p className='error'>Participants with completed assessment can only be deleted individually.</p>
          <input
            className="form-control"
            style={{ border: '1px solid #CACACA' }}
            type="text"
            onChange={this.searchForNames}
            value={this.state.search}
            placeholder='Search participants'
          />
          <div className='modal-table-container'>
            <TableGenerator
              config={{
                headers: ['PARTICIPANT', 'DATE OF BIRTH', <> Select{this.state.search === '' && <img
                  className="checkbox ms-2"
                  style={{ cursor: 'pointer',display: 'unset' }}
                  alt="Checkbox"
                  onClick={this.checkEveryone}
                  title='Select All'
                  src={this.allParticipantsChecked() ? CheckedImage : UncheckedImage}
                />}</>],
                contentRows: this.generateTableData(),
                rowFunction: this.handleClick,
                itemsPerPage: 99999
              }}
            />
          </div>
          <div className="btnContainer-edit-participant">
            <Button
              onClick={() => this.handleSubmit()}
              type="submit"
              className="small-button"
            >
              {this.state.isLoading && <Spinner />} Delete Participants
            </Button>
            <Button
              onClick={this.props.closeModal}
              className="small-blue-button-outlined"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Overlay>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    bulkDeleteParticipant: data => dispatch(actions.bulkDeleteParticipant(data)),
    closeModal: () => dispatch(actions.closeModal())
  };
};

export default connect(
  null,
  mapDispatchToProps
)(BulkDeleteModal);
