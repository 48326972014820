import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { useParams } from 'react-router';
import moment from 'moment';
import { json2csv } from 'json-2-csv';
import {
  Badge,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBell,
  faEdit,
  faFile,
  faTrash,
  faSignature,
  faCircleInfo,
  faCheck,
  faXmark,
  faClipboard,
  faExternalLink,
  faEnvelope,
  faGear,
  faDownload,
  faArchive,
  faSpinner,
  faArrowDownAZ,
  faTriangleExclamation,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { faCalendar, faFileLines } from '@fortawesome/free-regular-svg-icons';
import * as actions from '../../../../store/actions';
import Spinner from '../../../../components/Spinner/Spinner';
import Backdrop from '../../../../components/Backdrop/Backdrop';
import DropdownButton from '../../../../components/DropdownButton/DropdownButton';
import { ReactComponent as ReportIcon } from '../../../../assets/imgs/icon-survey-report.svg';
import { ReactComponent as ReferredIcon } from '../../../../assets/imgs/referred.svg';
import EmailSent from '../../../../assets/imgs/icon_notification_email_inprogress.svg';
import EmailSuccessIcon from '../../../../assets/imgs/icon_notification_email_success.svg';
import EmailFailIcon from '../../../../assets/imgs/icon_notification_email_error.svg';
import UnknownIcon from '../../../../assets/imgs/icon_unknown.svg';
import { getContent } from '../../../../utilities/getContent/getContent';
import { adjustDate, dateFormat } from '../../../../utilities/dates';
import activateItem from '../../../../utilities/activateItem';
import Timegate from './Timegate/Timegate';
import TableGeneratorAdvance from '../../../../components/TableGenerator/TableGeneratorAdvance';
import ConsentEdit from './ConsentEdit';
import Overlay from '../../../../components/Overlay/overlay';
import ConsentStatus from './ConsentStatus';
import NewsletterEdit from './NewsletterEdit';

const SurveyDetails = props => {
  const [loading, setLoading] = useState(false);
  const [showingOverlay, setShowingOverlay] = useState(false);

  const { surveyId } = useParams();
  // TODO : fix that
  const participantsAdded =
    props.surveyDetails &&
    props.surveyDetails.participants &&
    props.surveyDetails.participants.length > 0;

  const isAllK3 =
    props.surveyDetails &&
    props.surveyDetails.indicatorsV2 &&
    props.surveyDetails.indicatorsV2.every(i => i.variant === 'K-3');

  const proctorLink = () => {
    // const isAllK3 = props.surveyDetails.indicatorsV2.every(i => i.variant === 'K-3');
    const baseUrl = `${process.env.REACT_APP_V2_WEB_URL}/survey/proctor/`;
    if (isAllK3) {
      // TODO : do something better...
      const mapping = {
        '2641d169-f7f1-466d-a6a2-ef9e3c2fbcd2': {
          videoId: 'Global Satisfaction',
        },
        '3c782903-0e86-4032-a2de-b4c84b198389': {
          videoId: 'School Satisfaction',
        },
        'fbeddb89-42ef-40f0-9839-4bf5bfe4c330': {
          videoId: 'Grit',
        },
        'd7c57ba6-df12-4592-90bb-0ef9aaf67d12': {
          videoId: 'Ostracism',
        },
        '65e4b60e-68ed-4a84-889f-6ff5f136374e': {
          videoId: 'Anxiety',
        },
        'c109b121-bb43-44f7-8991-faa26479627f': {
          videoId: 'Depression',
        },
        'aa2f84f4-4a82-4916-9145-de8ab7ca3279': {
          videoId: 'Trauma',
        },
      };
      const joinedIds = props.surveyDetails.indicatorsV2
        .map(i => encodeURIComponent(mapping[i.indicatorId].videoId))
        .join(',');
      return `${baseUrl}${joinedIds}`;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (props.navOrg !== null || props.role === 'tm_admin') {
      if (props.surveyDetails.surveyId !== surveyId) {
        props.getSurvey(surveyId);
      } else {
        if (
          (props.navOrg.classification === 'school' && props.navOrg.orgId !== props.surveyDetails.orgId) ||
          (props.navOrg.classification === 'school-district' && props.navOrg.orgId !== props.surveyDetails.parentOrgId)
        ) {
          console.log(
            `navOrg ${props.navOrg.orgId} changed and different from survey org ${props.surveyDetails.orgId}`,
          );
          props.history.push(`/admin/survey/list#status=In+Progress%2CCreated`);
        }
      }
    }
    // }
  }, [surveyId, props.navOrg]);

  useEffect(() => {
    if (props.surveyNotificationData) {
      openFileDownloadModal();
    }
  }, [props.surveyNotificationData]);

  useEffect(() => {
    if (props.surveyDetails?.orgId) {
      props.listUsers(props.surveyDetails.orgId);
    }
  }, [props.surveyDetails?.orgId]);

  // useEffect(() => {
  // if (props.navOrg.classification === "school" && props.surveyDetails && props.navOrg.orgId !== props.surveyDetails.orgId) {
  //   console.log(`navOrg ${props.navOrg.orgId} changed and different from survey org ${props.surveyDetails.orgId}`);
  //   props.history.push(`/admin/survey/list#status=In+Progress%2CCreated`);
  // }
  // }, [props.navOrg])
  const hideOverlay = () => {
    setShowingOverlay(false);
  };

  const setTimegateForSelectedSurveyParticipants = async () => {
    const { timestampsByGrade } = props;
    let studentList = [];
    props.grades.map(grade => {
      const activationDate = timestampsByGrade[grade];
      const students = props.studentsByGrade[grade].map(item => {
        item.timegate = activationDate; // moment.utc(activationDate).unix();
        return item;
      });
      studentList = studentList.concat(students);
    });

    setLoading(false);
    await activateItem(studentList, props.idToken, 'surveyParticipant');
    setLoading(false);
  };

  const onGradeTimegateChange = (grade, value) => {
    const newTimestampsByGrade = { ...props.timestampsByGrade };
    newTimestampsByGrade[grade] = moment.utc(value).unix();
    props.setTimestampsByGrade(newTimestampsByGrade);
  };

  const getNameFromEmail = emailAddress => {
    if (props.champions && props.champions.length > 0) {
      const c = props.champions.find(
        champion => champion.email === emailAddress,
      );
      return c !== undefined ? `${c.firstName} ${c.lastName}` : emailAddress;
    } else return emailAddress;
  };

  const constructGrades = () => {
    const { timestampsByGrade } = props;

    return props.grades.map(grade => {
      const milliseconds = timestampsByGrade[grade]
        ? timestampsByGrade[grade] * 1000
        : null;
      return {
        label: grade,
        value: milliseconds ? new Date(milliseconds) : new Date(),
        //value: new Date(),
        onChange: onGradeTimegateChange,
      };
    });
  };

  const isUK = () => process.env.REACT_APP_STAGE === 'uk';

  // Participant Delete handler
  const deleteItemHandler = item => {
    const { surveyParticipantId } = item;
    openDeleteParticipantConfirmationModal(
      surveyParticipantId,
      surveyId,
      item.participantStatus,
    );
  };

  const openDeleteParticipantConfirmationModal = (
    surveyParticipantId,
    surveyId,
    participantStatus,
  ) => {
    props.openModal({
      modalType: 'GENERIC_MODAL',
      show: true,
      headline: 'Are you sure?',
      closeModal: props.closeModal,
      content: (
        <div>
          <p>
            {participantStatus === 'Completed' ? (
              <span>Responses and results will be permanently deleted. </span>
            ) : null}
            Are you sure you want to continue? You cannot undo this action.
          </p>
          <div className="btnContainer-edit-participant">
            <Button
              type="button"
              className="small-button"
              color="success"
              size="md"
              onClick={() =>
                props.deleteParticipantFromSurvey(surveyParticipantId, surveyId)
              }
            >
              Delete Participant
            </Button>
            <Button
              type="button"
              className="small-blue-button-outlined"
              size="md"
              onClick={props.closeModal}
            >
              Cancel
            </Button>
          </div>
        </div>
      ),
    });
  };

  const openDeleteSurveyConfirmationModal = () => {
    props.openModal({
      modalType: 'GENERIC_MODAL',
      show: true,
      headline: 'Are you sure?',
      content: (
        <div>
          <p>Are you sure you want to continue? You cannot undo this action.</p>
          <Button
            type="button"
            className="small-button green"
            size="md"
            id="locator_confirm-delete-survey-button"
            onClick={() =>
              props.deleteSurvey(
                props.surveyDetails.surveyId,
                redirectToSurveyList,
              )
            }
          >
            Delete Assessment
          </Button>
          <Button
            type="button"
            className="small-blue-button-outlined"
            size="md"
            onClick={props.closeModal}
          >
            Cancel
          </Button>
        </div>
      ),
    });
  };

  const openFileDownloadModal = async () => {
    let content;
    if (props.surveyNotificationData === 'loading') {
      content = (
        <p>
          Please wait, we're preparing your data{' '}
          <FontAwesomeIcon spin={true} icon={faGear} />
        </p>
      );
    } else if (props.surveyNotificationData.url) {
      content = (
        <p>
          Your data is ready. Please click{' '}
          <a href={props.surveyNotificationData.url}>here</a> to download.
        </p>
      );
    } else {
      content = <p>Sorry, something went wrong... please contact us.</p>;
    }
    props.openModal({
      modalType: 'GENERIC_MODAL',
      show: true,
      headline: 'Download Assessment Raw Data',
      headlineIcon: faDownload,
      content: content,
      onExit: props.resetAllNotificationData,
    });
  };

  const openProctorLinkModal = () => {
    props.openModal({
      modalType: 'GENERIC_MODAL',
      show: true,
      headline: 'Video Proctoring Link',
      content: (
        <div>
          <p>
            The link below will display a series of video for proctoring this
            assessment to students grade K to 3.
          </p>
          <p>
            Please treat this link as confidential and only share it with
            required and authorized users.
          </p>
          <pre
            style={{ lineHeight: 2, background: 'lightgray', padding: '0 1em' }}
            className="border"
          >
            {proctorLink()}
          </pre>
          <a href={proctorLink()} target="_blank" rel="noreferrer">
            <Button className="small-button green me-2">
              <FontAwesomeIcon className="me-2" icon={faExternalLink} />
              Open
            </Button>
          </a>
          <Button
            type="button"
            className="small-button green me-2"
            onClick={() => {
              navigator.clipboard.writeText(proctorLink());
            }}
          >
            <FontAwesomeIcon icon={faClipboard} className="me-2" />
            Copy Link
          </Button>
          <Button
            type="button"
            className="small-blue-button-outlined"
            onClick={props.closeModal}
          >
            Close
          </Button>
        </div>
      ),
    });
  };

  const closeSurvey = () => {
    props.validateSurvey(surveyId);
  };

  const addParticipantHandler = () => {
    const { surveyId, orgId } = props.surveyDetails;
    props.openModal({
      // surveyId: props.location.state.surveyId,
      surveyId,
      orgId,
      modalType: 'ADD_PARTICIPANT_MODAL',
      show: true,
      surveyName: isUK()
        ? props.surveyDetails.surveyName
          .replace('Uppergrades', 'Secondary')
          .replace('Elementary', 'Primary')
        : props.surveyDetails.surveyName,
      closeModal: props.closeModal,
      surveyStatus: props.surveyDetails.surveyStatus,
      surveyOrg: props.org,
    });
  };

  const editParticipantHandler = item => {
    props.openModal({
      modalType: 'EDIT_PARTICIPANT_MODAL',
      show: true,
      item: item,
      isEmployerAdmin: isEmployerAdmin(),
      surveyId: surveyId,
      surveyStatus: props.surveyDetails.surveyStatus,
      surveyName: isUK()
        ? props.surveyDetails.surveyName
          .replace('Uppergrades', 'Secondary')
          .replace('Elementary', 'Primary')
        : props.surveyDetails.surveyName,
      closeModal: props.closeModal,
    });
  };

  const notifyParentReportHandler = item => {
    props.openModal({
      modalType: 'NOTIFY_PARENT_MODAL',
      show: true,
      item: item,
      surveyId: surveyId,
      surveyStatus: props.surveyDetails.surveyStatus,
      closeModal: props.closeModal,
    });
  };

  const notifyHandler = item => {
    props.openModal({
      modalType: 'NOTIFY_MODAL',
      show: true,
      item: item,
      surveyId: surveyId,
      surveyStatus: props.surveyDetails.surveyStatus,
      closeModal: props.closeModal,
    });
  };

  const notificationLogHandler = async item => {
    await props.getNotificationLog(item.surveyParticipantId, surveyId);
    props.openModal({
      modalType: 'NOTIFY_HISTORY_MODAL',
      show: true,
      item: item,
      surveyId: surveyId,
      surveyStatus: props.surveyDetails.surveyStatus,
      closeModal: props.closeModal,
    });
  };

  const bulkEditParticipantHandler = () => {
    props.openModal({
      modalType: 'BULK_EDIT_PARTICIPANT_MODAL',
      participants: props.surveyDetails.participants,
      surveyId: surveyId,
      show: true,
      closeModal: props.closeModal,
    });
  };

  const bulkDeleteParticipantHandler = () => {
    props.openModal({
      modalType: 'BULK_DELETE_PARTICIPANT_MODAL',
      participants: props.surveyDetails.participants,
      surveyId: surveyId,
      show: true,
      closeModal: props.closeModal,
    });
  };

  const bulkNotificationHandler = () => {
    props.openModal({
      modalType: 'BULK_NOTIFY_PARENT_MODAL',
      show: true,
      surveyId: surveyId,
      surveyStatus: props.surveyDetails.surveyStatus,
      closeModal: props.closeModal,
    });
  };

  const bulkPdfHandler = () => {
    props.openModal({
      modalType: 'BULK_PDF_MODAL',
      participants: props.surveyDetails.participants,
      survey: props.surveyDetails,
      show: true,
      closeModal: props.closeModal,
    });
  };

  const bulkInviteHandler = () => {
    props.openModal({
      modalType: 'BULK_INVITE_MODAL',
      participants: props.surveyDetails.participants,
      surveyDetails: props.surveyDetails,
      surveyId: surveyId,
      show: true,
      closeModal: props.closeModal,
    });
  };

  const editSurveyDetails = () => {
    if (props.navOrg.orgId !== props.surveyDetails.orgId) {
      const option = props.schoolsList.find(
        o => o.value === props.surveyDetails.orgId,
      );
      props.schoolSelect(option);
    }
    props.history.push(`/admin/survey/edit/${surveyId}`);
  };

  const getReportIcon = (num, item) => {
    return (
      <div>
        <Link
          className="button-text report-link"
          to={{
            pathname: `/admin/reports/student?pId=${item.surveyParticipantId}`,
          }}
          target="_blank"
        >
          <span
            className="report-icon"
            data-num={num > 0 ? num : null}
            data-contacted={item.contacted}
          >
            <ReportIcon />
          </span>
        </Link>
        {item.referredTo ? <ReferredIcon /> : null}
      </div>
    );
  };
  const [dropdownOpenId, setDropdownOpenId] = useState(null);

  const toggle = id => {
    setDropdownOpenId(prevId => (prevId === id ? null : id));
  };

  const getContextMenu = item => {
    const isOpen = dropdownOpenId === item.surveyParticipantId;
    return (
      <Dropdown
        isOpen={isOpen}
        toggle={() => toggle(item.surveyParticipantId)}
        key={item.surveyParticipantId}
      >
        <DropdownToggle color="trasparent">...</DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => editParticipantHandler(item)}>
            <FontAwesomeIcon icon={faEdit} className="blue-fill-icon" /> Edit
          </DropdownItem>
          <DropdownItem onClick={() => deleteItemHandler(item)}>
            <FontAwesomeIcon icon={faTrash} className="blue-fill-icon" />
            Delete
          </DropdownItem>
          {item.participantStatus === 'Completed' ? (
            item.parentEmail || item.participantEmail || item.parentMobile ? (
              item.emailStatus === 'Delivery' ||
                item.emailStatus === 'Bounce' ? (
                <DropdownItem onClick={() => notifyParentReportHandler(item)}>
                  <FontAwesomeIcon
                    icon={faBell}
                    className="blue-fill-icon"
                    onClick={() => { }}
                  />
                  Resend Notification
                </DropdownItem>
              ) : (
                <DropdownItem onClick={() => notifyParentReportHandler(item)}>
                  <FontAwesomeIcon
                    icon={faBell}
                    className="blue-fill-icon"
                    onClick={() => { }}
                  />
                  Send Notification
                </DropdownItem>
              )
            ) : null
          ) : null}
          {item.participantStatus !== 'Completed' ? (
            item.emailStatus === 'Delivery' || item.emailStatus === 'Bounce' ? (
              <DropdownItem onClick={() => notifyHandler(item)}>
                <FontAwesomeIcon
                  icon={faBell}
                  className="blue-fill-icon"
                  onClick={() => { }}
                />
                Resend Invite
              </DropdownItem>
            ) : (
              <DropdownItem onClick={() => notifyHandler(item)}>
                <FontAwesomeIcon
                  icon={faBell}
                  className="blue-fill-icon"
                  onClick={() => { }}
                />
                Send Invite
              </DropdownItem>
            )
          ) : null}

          <DropdownItem onClick={() => notificationLogHandler(item)}>
            <FontAwesomeIcon
              icon={faFile}
              className="blue-fill-icon"
              onClick={() => { }}
            />
            Notification History
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const generateTableData = () => {
    // Waits for survey details to be populated
    if (
      props.surveyDetails &&
      props.surveyDetails.participants &&
      props.surveyDetails.participants.length > 0
    ) {
      // if (props.surveyDetails.LastEvaluatedKey && props.surveyDetails.LastEvaluatedKey.surveyParticipantId) {
      //   setState({ nextKey: props.surveyDetails.LastEvaluatedKey.surveyParticipantId, next: true });
      // }
      const isAdult = props.surveyDetails.surveyName.match(/Adult/i) === null;
      const gradeText = isAdult
        ? getContent('admin_participant-entry-grade')
        : getContent('admin_participant-entry-year');
      const emailStatusIconMap = {
        Delivery: (
          <img src={EmailSuccessIcon} alt="" title="Email was delivered" />
        ),
        Bounce: <img src={EmailFailIcon} alt="" title="Email Bounced" />,
        Blocked: <img src={EmailFailIcon} alt="" title="Email was Blocked" />,
        Sending: <img src={EmailSent} alt="" title="Email in progress" />,
        // 'Blacklisted': <img src={EmailBlacklistedIcon} alt="" title="Email address is blacklisted." />,
      };
      const getStatus = (item) => {
        return (
          <div className="d-flex">
            <div>{item.participantStatus}</div>
            <div className="tableHeader__calendar">
              {item.participantStatus === 'Completed' && item.surveyStartedAt && item.surveySubmittedAt ? (
                <>
                  <FontAwesomeIcon
                    icon={faCalendar}
                    id={`tooltip-${item.participantSurveyAuthCode}`}
                  />
                  <UncontrolledTooltip
                    target={`tooltip-${item.participantSurveyAuthCode}`}
                  >
                    <div>
                      Started:{' '}
                      {moment
                        .utc(item.surveyStartedAt)
                        .format('DD-MMM-YYYY HH:mm:ss')}
                    </div>
                    <div>
                      Submitted:{' '}
                      {moment
                        .utc(item.surveySubmittedAt)
                        .format('DD-MMM-YYYY HH:mm:ss')}
                    </div>
                  </UncontrolledTooltip>
                </>
              ) : null}
              {item.participantStatus === 'Not Started' && item.sessionLog && item.sessionLog.length > 0 ? (
                <>
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    id={`tooltip-${item.participantSurveyAuthCode}`}
                  />
                  <UncontrolledTooltip
                    target={`tooltip-${item.participantSurveyAuthCode}`}
                  >
                    <div>
                      Connected but did not complete on:{' '}
                      {moment
                        .unix(item.sessionLog[0].timestamp)
                        .format('DD-MMM-YYYY HH:mm:ss')}
                    </div>
                  </UncontrolledTooltip>
                </>
              ) : null}
            </div>
          </div>
        )
      }

      const participantSearchKeyEncoded = (item) => {
        return encodeURIComponent(`${item.lastName}#${item.firstName}#${item.birthDate}`);
      }
      // const status = <div>{item.participantStatus}</div>;
      //       if (item.participantStatus === "Not Started" && item.sessionLog && item.sessionLog.length > 0) {
      //         return "Started";
      //       } else {
      //         return item.participantStatus;
      //       }
      //     }
      return props.surveyDetails.participants.map(item => {
        return {
          participant:
            <>
              <div
                className="d-flex justify-content-between"
              // style={{ maxWidth: '130px' }}
              >
                <div>{`${item.lastName}, ${item.firstName
                  } ${item.middleName || ''}`}</div>
                {props.surveyDetails.hasOwnProperty('consentType') &&
                  props.surveyDetails.consentType !== null &&
                  props.surveyDetails.consentLog?.length > 0 ? (
                  <div>
                    {props.surveyDetails.consentType ==
                      'CONSENT_TYPE_ACTIVE_CONSENT' ? (
                      item.consentGranted === true ? (
                        <FontAwesomeIcon
                          title="Consent granted (positive response)"
                          icon={faCheck}
                          className="ml-2"
                          color="green"
                        />
                      ) : (
                        <FontAwesomeIcon
                          title="Consent denied (no response)"
                          className="ml-2"
                          icon={faXmark}
                          color="red"
                        />
                      )
                    ) : item.consentGranted === false ? (
                      <FontAwesomeIcon
                        title="Consent denied (negative response)"
                        className="ml-2"
                        icon={faXmark}
                        color="red"
                      />
                    ) : (
                      <FontAwesomeIcon
                        title="Consent granted (no response)"
                        icon={faCheck}
                        className="ml-2"
                        color="green"
                      />
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="grade-label">
                {gradeText}: {item.grade} &nbsp; <Link
                  className="hover-only"
                  to={{
                    pathname: `/admin/participant/search/key/${participantSearchKeyEncoded(item)}`,
                  }}
                  target="_blank"
                  title="Search previous results"
                ><FontAwesomeIcon icon={faSearch} />
                </Link>
                <Link
                  className="hover-only ms-1"
                  disabled={item.participantStatus !== 'Completed'}
                  to={{
                    pathname: `/admin/reports/parent?pId=${item.surveyParticipantId}`,
                  }}
                  target="_blank"
                  title={item.participantStatus === 'Completed' ? "See parent report" : "No report available"}
                ><FontAwesomeIcon icon={faFile} />
                </Link>

              </div>
            </>,
          dob: adjustDate(item.birthDate).format(dateFormat),
          login: isEmployerAdmin() ? null : item.participantSurveyAuthCode
            .length === 16 ? (
            <span id="locator_survey-participant-code">
              {item.participantSurveyAuthCode.match(/.{1,4}/g).join('-')}
            </span>
          ) : (
            <span id="locator_survey-participant-code">
              {item.participantSurveyAuthCode}
            </span>
          ),
          status: getStatus(item),
          result:
            item.participantStatus !== 'Completed' || isEmployerAdmin() ? (
              <span>-</span>
            ) : (
              getReportIcon(item.priority, item)
            ),
          invite:
            item.parentEmail || item.participantEmail ? (
              emailStatusIconMap[item.inviteStatus] || '--' // Not sent yet
            ) : (
              <img
                src={UnknownIcon}
                alt="No email on file"
                title="No email on file"
              />
            ),
          notifications:
            item.parentEmail || item.participantEmail ? (
              item.participantStatus === 'Completed' ? (
                <span className="report-icon">
                  {' '}
                  {emailStatusIconMap[item.emailStatus] || '--' // Not sent yet
                  }
                </span>
              ) : (
                <span></span> // Not Completed Yet
              )
            ) : (
              <img
                src={UnknownIcon}
                alt="No email on file"
                title="No email on file"
              /> // No email provided
            ),
          menu: getContextMenu(item),
          surveyParticipant: item,
        };
      });
    }

    return [];
  };

  const redirectToSurveyList = () => {
    props.resetSurveyList();
    props.history.push(`/admin/survey/list`);
  };

  const exportCSVFile = async fileTitle => {
    await props.getAllParticipantData(surveyId);
    if (props && props.surveyParticipants) {
      const isAdult = props.surveyDetails.surveyName.match(/Adult/i) !== null;
      const gradeText = isAdult
        ? getContent('admin_participant-entry-year')
        : getContent('admin_participant-entry-grade');
      const items = props.surveyParticipants;
      const filteredItems = items.map(item => {
        return {
          'Auth Code':
            item.participantSurveyAuthCode.length === 16
              ? item.participantSurveyAuthCode.match(/.{1,4}/g).join('-')
              : item.participantSurveyAuthCode,
          [gradeText]: item.grade,
          'First Name': item.firstName,
          'Last Name': item.lastName,
          'Middle Name': item.middleName,
          'Birth Date': adjustDate(item.birthDate).format(dateFormat),
          'Group Name': item.groupName,
          'Participant Email': item.participantEmail,
        };
      });
      await json2csv(
        filteredItems,
        (err, csv) => {
          let exportedFilename = fileTitle + '.csv' || 'export.csv';
          var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportedFilename);
          } else {
            var link = document.createElement('a');
            if (link.download !== undefined) {
              // feature detection
              // Browsers that support HTML5 download attribute
              var url = URL.createObjectURL(blob);
              link.setAttribute('href', url);
              link.setAttribute('download', exportedFilename);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        },
        { emptyFieldValue: '' },
      );
    }
  };
  const isEmployerAdmin = () =>
    props.parentOrg &&
    props.parentOrg.employerProvider &&
    props.parentOrg.employerProvider === 'Employer' &&
    props.role === 'district_champion';



  const AddParticipantCard = () => {
    return (
      <div className="survey-info__empty">
        <div>
          <div>
            This survey is currently empty. Click the button to add participants
            either manually or via rostering.
          </div>
          <div className="d-flex justify-content-center">
            <Button className="button green" onClick={addParticipantHandler}>
              Add participants
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const getDropdownButtonList = () => {
    const dropdownItems = [
      // {
      //   label: 'Send Email to Parents',
      //   onClick: () => {
      //     setShowingOverlay('newsletter');
      //   },
      // },

      ...(props.surveyDetails.surveyStatus === 'Completed'
        ? [
          {
            label: 'Bulk Send Parent Report',
            onClick: bulkNotificationHandler,
          },
          {
            label: 'Bulk Download Parent Report PDFs',
            onClick: bulkPdfHandler,
          },
        ]
        : []),
      ...(props.surveyDetails.surveyStatus === 'Created'
        ? [
          {
            label: 'Bulk Invite Participants',
            onClick: bulkInviteHandler,
          },
        ]
        : []),
      ...(props.surveyDetails.surveyStatus === 'In Progress'
        ? [
          {
            label: 'Bulk Invite Participants',
            onClick: bulkInviteHandler,
          },
          {
            label: 'Bulk Download Parent Report PDFs',
            onClick: bulkPdfHandler,
          },
        ]
        : []),
      ...(props.surveyDetails.surveyStatus === 'In Progress' &&
        !isEmployerAdmin()
        ? [
          {
            label: 'Download Participant List',
            onClick: () => {
              exportCSVFile(
                (isUK()
                  ? props.surveyDetails.surveyName
                    .replace('Uppergrades', 'Secondary')
                    .replace('Elementary', 'Primary')
                  : props.surveyDetails.surveyName) + '-participants',
              );
            },
          },
        ]
        : []),
      ...(props.surveyDetails.surveyStatus === 'Created' && !isEmployerAdmin()
        ? [
          {
            label: 'Download Participant List',
            onClick: () => {
              exportCSVFile(
                (isUK()
                  ? props.surveyDetails.surveyName
                    .replace('Uppergrades', 'Secondary')
                    .replace('Elementary', 'Primary')
                  : props.surveyDetails.surveyName) + '-participants',
              );
            },
          },
        ]
        : []),
      ...(props.surveyDetails.surveyStatus === 'Completed' && !isEmployerAdmin()
        ? [
          {
            label: 'Download Participant List',
            onClick: () => {
              exportCSVFile(
                (isUK()
                  ? props.surveyDetails.surveyName
                    .replace('Uppergrades', 'Secondary')
                    .replace('Elementary', 'Primary')
                  : props.surveyDetails.surveyName) + '-participants',
              );
            },
          },
        ]
        : []),
      {
        label: 'Bulk Delete Participant',
        onClick: bulkDeleteParticipantHandler,
      },
      {
        label: 'Bulk Edit Participant',
        onClick: bulkEditParticipantHandler,
      },
      {
        label: 'Add Participant',
        onClick: addParticipantHandler,
      },
      {
        label: 'Download Raw Data',
        onClick: () => {
          props.getAllNotificationData([surveyId]); // note: array!
        },
      },
      {
        label: 'Add/Edit Consent Information',
        onClick: () => {
          setShowingOverlay('consent');
        },
        disabled:
          props.surveyDetails.surveyStatus !== 'Created' ||
          props.surveyDetails.consentLog !== undefined,
      },
      ...(isAllK3
        ? [
          {
            label: 'Get K-3 Video',
            onClick: openProctorLinkModal,
          },
        ]
        : []),
      // ...(isAllK3
      //   ? [
      //       {
      //         label: 'Get proctoring link',
      //         onClick: openProctorLinkModal,
      //       },
      //     ]
      //   : []),
    ]
      .filter(Boolean)
      .filter(el => !Array.isArray(el));
    return dropdownItems;
  };

  const showParticipantInformation =
    props.role === 'school_champion' ||
    props.role === 'district_champion' ||
    (props.parentOrg &&
      props.parentOrg.isTMAllowed &&
      props.role === 'tm_admin');

  const titleLogin = (
    <div>
      LOGIN <FontAwesomeIcon icon={faCircleInfo} id="title-login" />
      <UncontrolledTooltip target={'title-login'}>
        This is the unique code for each participant. If necessary, enter this
        code at assessment.terracemetrics.org/survey for manual administration.
      </UncontrolledTooltip>
    </div>
  );

  const titleResult = (
    <>
      RESULT <FontAwesomeIcon icon={faCircleInfo} id="title-result" />
      <UncontrolledTooltip target={'title-result'}>
        Click on icon to see individual results
      </UncontrolledTooltip>
    </>
  );

  const titleInvitation = (
    <div>
      INVITATION <FontAwesomeIcon icon={faCircleInfo} id="title-invitation" />
      <UncontrolledTooltip target={'title-invitation'}>
        A green checkmark indicates that the invite was successfully sent ; a
        red ‘x’ means that the invite could not be sent
      </UncontrolledTooltip>
    </div>
  );

  const titleMore = (
    <div>
      <FontAwesomeIcon icon={faCircleInfo} id="title-more" />
      <UncontrolledTooltip target={'title-more'}>
        Click the three dots to edit information, add notes, send invitation, or
        send parent report.
      </UncontrolledTooltip>
    </div>
  );

  const ssoEnabledBadge = props.surveyDetails.participants.length > 0
    && props.surveyDetails.participants.every(p => p.hasOwnProperty("sourceId") && p.sourceId && p.sourceSystem) ?
    <Badge color="success" className="ms-2 text-capitalize" title="Student Login is enabled with this provider" >{props.surveyDetails.participants[0].sourceSystem}</Badge> : null;
  return props.isLoading ? (
    <Backdrop>
      <Spinner />
    </Backdrop>
  ) : (
    <div className="create-survey-container">
      <div className="survey-info d-print-none d-flex justify-content-between">
        <div>
          <strong>Assessment Information</strong>
          <div>{props.surveyDetails.surveyName}</div>
          {props.surveyDetails.surveyStatus == 'Completed' && (
            <div>
              <Link
                className="button-text"
                to={{
                  pathname: `/admin/reports/survey?id=${props.surveyDetails.surveyId}`,
                }}
                target="_blank"
              >
                Report <FontAwesomeIcon icon={faFileLines}></FontAwesomeIcon>
              </Link>
            </div>
          )}

          <div>
            <Badge className="" title="Status" color="secondary">
              {props.surveyDetails.surveyStatus}
            </Badge>
            <Badge className="ms-2" title="Period" color="secondary">
              {props.surveyDetails.semester + ' ' + props.surveyDetails.year}
            </Badge>
            {props.surveyDetails.fixedOrder && (
              <Badge
                className="ms-2"
                title="No randomization"
                color="secondary"
              >
                <FontAwesomeIcon icon={faArrowDownAZ} />
              </Badge>
            )}
            {ssoEnabledBadge}
          </div>
        </div>
        <div>
          <strong>
            Indicators{' '}
            <FontAwesomeIcon
              icon={faCircleInfo}
              color="#3c7dc2"
              id="indicators-list"
            />
          </strong>
          <UncontrolledTooltip target={'indicators-list'}>
            These are the indicators that were administrated.
          </UncontrolledTooltip>
          <div className="create-survey-container__indicators-list">
            <ul>
              {props.surveyDetails && props.surveyDetails.indicators
                ? props.surveyDetails.indicators.map((item, idx) => {
                  return <li key={`indicatorListItem-${idx}`}>{item}</li>;
                })
                : null}
            </ul>
          </div>
        </div>
        <div>
          <strong>Designated Recipients</strong>
          {!props.championsLoaded && (
            <FontAwesomeIcon icon={faSpinner} className="ms-2 fa-spin" />
          )}
          {props.surveyDetails.designatedRecipient &&
            props.surveyDetails.designatedRecipient.length > 0 ? (
            <>
              {props.surveyDetails.designatedRecipient.map((e, idx) => (
                <div key={`dr-${idx}`}>
                  <FontAwesomeIcon title={e} icon={faEnvelope} />{' '}
                  {getNameFromEmail(e)}
                </div>
              ))}
            </>
          ) : (
            <p>None defined</p>
          )}
        </div>
        <div className="create-survey-container__buttons-flex">
          <DropdownButton
            items={[
              {
                label: (
                  <>
                    <FontAwesomeIcon className="me-2" icon={faEdit} />
                    Edit
                  </>
                ),
                onClick: editSurveyDetails,
                disabled: props.surveyDetails.surveyStatus === 'Completed',
                title: 'Edit assessment details',
              },
              {
                label: (
                  <>
                    <FontAwesomeIcon className="me-2" icon={faCheck} />
                    Close
                  </>
                ),
                onClick: closeSurvey,
                disabled: props.surveyDetails.surveyStatus === 'Completed',
                title: 'Close the assessment',
              },
              {
                label: (
                  <>
                    <FontAwesomeIcon className="me-2" icon={faArchive} />
                    {props.surveyDetails.isArchived ? 'Unarchive' : 'Archive'}
                  </>
                ),
                onClick: () =>
                  props.archiveSurvey(
                    props.surveyDetails.surveyId,
                    redirectToSurveyList,
                    !props.surveyDetails.isArchived,
                  ),
              },
              {
                label: (
                  <>
                    <FontAwesomeIcon className="me-2" icon={faTrash} />
                    Delete
                  </>
                ),
                onClick: openDeleteSurveyConfirmationModal,
                disabled: props.surveyDetails.surveyStatus !== 'Created',
              },
            ]}
            label={'Assessment'}
            className="surveyDetailsDropDownButton"
          />
          {/* <Button
            onClick={() =>
              props.archiveSurvey(
                props.surveyDetails.surveyId,
                redirectToSurveyList,
                !props.surveyDetails.isArchived,
              )
            }
            className="small-blue-button-outlined"
          >
            {props.surveyDetails.isArchived ? 'Unarchive' : 'Archive'}
          </Button>
          {props.surveyDetails.surveyStatus === 'Created' ? (
            <Button
              onClick={() => openDeleteSurveyConfirmationModal()}
              className="small-blue-button-outlined"
              id="locator_delete-survey-button"
            >
              Delete
            </Button>
          ) : null} */}
        </div>
      </div>
      <div className="d-print-none create-survey-container__details">
        <div className="mb-0">{`Participant List Size - ${props.surveyDetails &&
          (props.surveyDetails.count ||
            (props.surveyDetails.participants &&
              props.surveyDetails.participants.length) ||
            0)} Participants`}</div>
        <div className="mb-0 statusCountText d-print-none">
          {props.surveyDetails && props.surveyDetails.statusCounts
            ? `Not Started: ${props.surveyDetails.statusCounts.notStarted} \u00A0 Not Completed: ${props.surveyDetails.statusCounts.notCompleted} \u00A0 Completed: ${props.surveyDetails.statusCounts.completed}`
            : null}
        </div>
        {showParticipantInformation ? (
          <div>
            <DropdownButton
              items={getDropdownButtonList()}
              label={'Participants'}
              className="surveyDetailsDropDownButton"
            />
          </div>
        ) : null}
      </div>
      <ConsentStatus surveyId={surveyId} />

      {props.org?.idp ? (
        <Timegate
          grades={constructGrades()}
          save={setTimegateForSelectedSurveyParticipants}
          isLoading={loading}
        />
      ) : null}

      {showParticipantInformation ? (
        participantsAdded ? (
          <TableGeneratorAdvance
            config={{
              headers: [
                {
                  title: 'PARTICIPANT',
                  colKey: 'participant',
                  isSorting: true,
                }, // isSorting enables sorting
                { title: 'DATE OF BIRTH', colKey: 'dob' },
                { title: titleLogin, colKey: 'login' },
                { title: 'STATUS', colKey: 'status', isSorting: true },
                {
                  title: titleResult,
                  colKey: 'result',
                  isSorting: true,
                  direction: 'desc',
                },
                { title: titleInvitation, colKey: 'invite' },
                { title: 'NOTIFICATIONS', colKey: 'notifications' },
                { title: titleMore, colKey: 'menu' },
              ],
              itemsPerPage: 40,
              contentRows: generateTableData(),
              sortableColumns: {
                status: {
                  getValue: rowItem => {
                    return {
                      Completed: 1,
                      'Not Started': 2,
                      'Not Completed': 3,
                    }[rowItem.surveyParticipant.participantStatus];
                  },
                  compareFn: (a, b) => b - a,
                },
                result: {
                  getValue: rowItem =>
                    rowItem.surveyParticipant.priority ||
                    (rowItem.surveyParticipant.participantStatus === 'Completed'
                      ? 6
                      : 7),
                  compareFn: (a, b) => b - a,
                },
                participant: {
                  getValue: rowItem =>
                    rowItem.surveyParticipant.lastName.toLowerCase() +
                    ' ' +
                    rowItem.surveyParticipant.firstName.toLowerCase(),
                  compareFn: (a, b) => b.localeCompare(a),
                },
              },
            }}
          />
        ) : (
          <AddParticipantCard />
        )
      ) : null}
      {showingOverlay === 'consent' ? (
        <Overlay
          isOpen={showingOverlay === 'consent'}
          variant="large"
          onToggle={hideOverlay}
          title={
            <div className="d-flex">
              <FontAwesomeIcon
                icon={faSignature}
                className="blue-fill-icon"
                style={{ marginRight: '1rem' }}
              />
              Consent Options
            </div>
          }
        >
          <ConsentEdit onSuccess={hideOverlay} surveyId={surveyId} />
        </Overlay>
      ) : null}
      {showingOverlay === 'newsletter' ? (
        <Overlay
          isOpen={showingOverlay === 'newsletter'}
          variant="large"
          onToggle={hideOverlay}
          title={
            <div className="d-flex">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="blue-fill-icon me-1"
              />
              Send Email to Parents / Guardians
            </div>
          }
        >
          <NewsletterEdit onSubmit={hideOverlay} surveyId={surveyId} />
        </Overlay>
      ) : null}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    isLoading: state.surveyCrud.isLoading,
    navOrg: state.navigation.org,
    schoolsList: state.navigation.schoolsList,
    surveyDetails: state.surveyCrud.surveyDetails,
    surveyParticipants: state.surveyCrud.surveyParticipants,
    surveyNotificationData: state.surveyCrud.surveyNotificationData,
    surveyNotificationResponses: state.surveyCrud.surveyNotificationResponses,
    parentOrg: state.surveyCrud.surveyOrg?.parentOrg,
    org: state.surveyCrud.surveyOrg,
    role: state.auth.role,
    idToken: state.auth.idToken,
    grades: state.surveyCrud.surveyDetails.grades,
    studentsByGrade: state.surveyCrud.surveyDetails.studentsByGrade,
    timestampsByGrade: state.surveyCrud.surveyDetails.timestampsByGrade,
    champions: state.users.users,
    championsLoaded: state.users.isLoaded,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openModal: modalProps => dispatch(actions.openModal(modalProps)),
    closeModal: () => dispatch(actions.closeModal()),
    getSurvey: surveyId => dispatch(actions.getSurvey(surveyId)),
    deleteSurvey: (surveyId, cb) =>
      dispatch(actions.deleteSurvey(surveyId, cb)),
    validateSurvey: (surveyId, cb) =>
      dispatch(actions.validateSurvey(surveyId, cb)),
    clearSurveyDetails: () => dispatch(actions.clearSurveyDetails()),
    deleteParticipantFromSurvey: (participantId, surveyId) =>
      dispatch(actions.deleteParticipantFromSurvey(participantId, surveyId)),
    getAllParticipantData: surveyId =>
      dispatch(actions.getAllParticipantData(surveyId)),
    getAllNotificationData: surveyIds =>
      dispatch(actions.getAllNotificationData(surveyIds)),
    resetAllNotificationData: () =>
      dispatch(actions.resetAllNotificationData()),
    getAllNotificationResponses: surveyId =>
      dispatch(actions.getAllNotificationResponses(surveyId)),
    getNotificationLog: (participantId, surveyId) =>
      dispatch(actions.getNotificationLog(participantId, surveyId)),
    clearCreateSurveyState: () => dispatch(actions.clearCreateSurveyState()),
    toggleGradeForSurvey: grade =>
      dispatch(actions.toggleGradeForSurvey(grade)),
    setStudentsByGrade: students =>
      dispatch(actions.setStudentsByGrade(students)),
    setTimestampsByGrade: timestampsByGrade =>
      dispatch(actions.setTimestampsByGrade(timestampsByGrade)),
    archiveSurvey: (surveyId, cb, isArchived = true) =>
      dispatch(actions.archiveSurveys([surveyId], cb, isArchived)),
    listUsers: orgId => dispatch(actions.listUsers(orgId, true)),
    resetSurveyList: () => dispatch(actions.listSurveySuccess([], null)),
    // orgChange: (orgId) => dispatch(actions.orgChange(orgId)),
    schoolSelect: selectOption => dispatch(actions.schoolSelect(selectOption)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SurveyDetails),
);
